<template>
  <div class="my-5 mx-auto flex sm:flex-row flex-col w-2/3">
    <div class="flex flex-col justify-center items-center inline sm:mr-4">
      <div class="sm:px-4 mb-5 sm:min-w-[16rem] max-w-[12rem]">
        <img src="@/assets/K.K.jpg" alt="Web開発ツール">
      </div>
      <div class="px-4 text-center whitespace-nowrap">
        <p>
          代表兼エンジニア
          <br>
          小島一也
        </p>
      </div>
    </div>
    <div class="pt-5">
      <p>
        皆様、こんにちは。私は10年以上にわたりWebアプリケーションの開発者としてフリーランスで活動しています。
      </p>
      <br>
      <p>
        Ruby、Python、Go言語をはじめとする多様なプログラミング言語を使い、AWSやGCPといったクラウドサービスを駆使してWebシステムの開発を行うことを得意としています。
      </p>
      <br>
      <p>
        これまで数々のプロジェクトに関与し、特に新技術の選定やシステム構築を中心的な役割として担当してきました。その一環としてシステムの耐障害性の向上や、問題点の迅速な調査と解消を行い、プロジェクトの成功に貢献してきました。
      </p>
      <br>
      <p>
        プライベートではラケットスポーツ、特にテニスとバトミントンが好きで、休日にはこれらのスポーツを楽しんでいます。また、2人の息子がおり家族と共に過ごす時間も大切にしています。
      </p>
      <br>
      <p>
        私のスキルと経験を活かし、お客様のプロジェクトが成功につながるよう全力でサポートさせていただきます。どうぞよろしくお願い申し上げます。
      </p>
    </div>
  </div>
</template>
<script setup>
</script>