<template>
  <div class="flex flex-col items-center justify-items-center py-5">
    <h2 class="text-xl border-b-4 px-4 font-semibold">Webシステム開発</h2>
  </div>
  <div class="flex sm:flex-row flex-col mb-10">
    <div class="sm:px-4 mb-5 sm:w-1/3">
      <img class="sm:w-auto w-2/3 mx-auto" src="@/assets/web-design.jpg" alt="Webデザイン">
    </div>
    <div class="px-4 sm:w-2/3">
      <p>当社では、お客様の開発プロジェクトに参画しての開発支援を行っています。</p>
      <br>
      <h3 class="text-lg font-semibold">開発プロジェクトへの参画事例</h3>
      <ul class="text-sm list-disc ml-4">
        <li>大手フィットネスジムアプリケーションプロジェクトにおいて、アプリコンテンツ/ユーザー情報の管理システムを開発。</li>
        <li>eKYC（電子本人確認）のSaaSプロダクト開発。</li>
        <li>法人カードの決済システム開発。</li>
        <li>Solidityを使用したWeb3アプリケーション開発（DApp開発）。</li>
        <li>機械学習を用いた自動音声認識サービスの開発。</li>
        <li>不動産売買事業者向けの営業支援システム（SFA）開発。</li>
        <li>業務システムのSaaS比較サイトの開発。</li>
      </ul>
    </div>
  </div>
</template>