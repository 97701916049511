<template>
  <form class="my-12" @submit.prevent="submit">
    <div class="space-y-12 max-w-3xl mx-auto">
      <div class="border-b border-gray-900/10 pb-8 flex flex-col items-center justify-items-center mx-3">
        <h2 class="text-xl border-b-4 font-semibold leading-7 text-gray-900">お問い合わせ</h2>
        <p class="my-3 text-sm leading-6 text-gray-600">お仕事のご依頼などはこちらからお気軽にお問い合わせください。</p>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 min-w-full md:px-28 px-0">
          <div class="sm:col-span-4">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">お名前</label>
            <div class="mt-2">
              <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input type="text" name="name" id="name" required
                       v-model="inquiry.name"
                       class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div class="sm:col-span-4">
            <label for="name" class="block text-sm font-medium leading-6 text-gray-900">メールアドレス</label>
            <div class="mt-2">
              <div
                  class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input type="email" name="email" id="email" required
                       v-model="inquiry.email"
                       class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div class="sm:col-span-4 col-span-full">
            <label for="about" class="block text-sm font-medium leading-6 text-gray-900">お問い合わせ内容</label>
            <div class="mt-2">
              <textarea id="detail" name="detail" rows="5" required
                        v-model="inquiry.detail"
                        class="block w-full px-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-6 flex items-center justify-center gap-x-6">
      <button type="submit"
              class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        送信
      </button>
    </div>
  </form>
</template>

<script>
import { getFirestore, addDoc, collection } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { useToast } from "vue-toastification";

export default {
  name: 'InquiryForm',
  setup() {
    const toast = useToast()
    return { toast }
  },
  data() {
    return {
      inquiry: {
        name: '',
        email: '',
        detail: ''
      }
    }
  },
  methods: {
    async submit() {
      const app = initializeApp({
        apiKey: 'AIzaSyBQiSjGaP8kj7TzlmYOtwpkbYUjzMGwGlU',
        authDomain: 'improver-965f9.firebaseapp.com',
        databaseURL: 'https://improver-965f9.firebaseio.com',
        projectId: 'improver-965f9',
        storageBucket: 'improver-965f9.appspot.com',
        messagingSenderId: '1011565111497',
        appId: '1:1011565111497:web:b588b29353dca0d0ea4518'
      })
      const db = getFirestore(app)
      try {
        await addDoc(collection(db, 'inquiries'), {
          to: ['kkojima@improver.co.jp'],
          message: {
            subject: '【インプルーバー合同会社】お問い合わせがありました',
            text: [
              '企業サイト経由でお問い合わせがありました。',
              '',
              '【お名前】',
              `${this.inquiry.name}\n`,
              '【メールアドレス】',
              `${this.inquiry.email}\n`,
              '【お問い合わせ内容】',
              `${this.inquiry.detail}\n`
            ].join('\n')
          }
        })
        this.toast.success('お問い合わせ内容を送信いたしました。')
        this.inquiry.name = ""
        this.inquiry.email = ""
        this.inquiry.detail = ""
      } catch (e) {
        this.toast(`お問い合わせ内容の送信に失敗しました。\nerror: ${e}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
