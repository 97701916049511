<template>
  <header>
    <nav class="mx-auto flex max-w-7xl items-center justify-between lg:px-8 px-4 " aria-label="Global menu">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">インプルーバー合同会社</span>
          <img class="md:h-18 h-14 w-auto" src="@/assets/logo.png" alt="インプルーバー合同会社">
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" @click="menuOpen = true"  class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
          <span class="sr-only">メニューを開く</span>
          <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12 lg:justify-end">
        <a href="#service" class="text-sm font-semibold leading-6 text-gray-900">SERVICE</a>
        <a href="#company" class="text-sm font-semibold leading-6 text-gray-900">COMPANY</a>
        <a href="#inquiry" class="text-sm font-semibold leading-6 text-gray-900">CONTACT</a>
      </div>
    </nav>

    <div :class="{ hidden: !menuOpen }" class="lg:hidden" role="dialog" aria-modal="true">
      <div class="fixed inset-0 z-10"></div>
      <div
          class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 z-20">
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">インプルーバー合同会社</span>
            <img class="h-8 w-auto" src="@/assets/logo.png?shade=600" alt="インプルーバー合同会社">
          </a>
          <button type="button" @click="menuOpen = false" class="-m-2.5 rounded-md p-2.5 text-gray-700">
            <span class="sr-only">Close menu</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                 aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6" @click="menuOpen = false">
              <a href="#service"
                 class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">SERVICE</a>
              <a href="#company"
                 class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">COMPANY</a>
              <a href="#inquiry"
                 class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">CONTACT</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
  data() {
    return {
      menuOpen: false
    }
  }
}
</script>