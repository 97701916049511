<template>
  <div class="my-5 mx-4">
    <div class="flex flex-col items-center justify-items-center pb-5">
      <h2 class="text-xl border-b-4 px-4 font-semibold">ITスキル学習コンテンツ制作</h2>
    </div>
    <div class="flex sm:flex-row flex-col mb-10">
      <div class="sm:px-4 mb-5 sm:w-1/3">
        <img class="sm:w-auto w-2/3 mx-auto" src="@/assets/web-dev-tool.png" alt="Web開発ツール">
      </div>
      <div class="px-4 sm:w-2/3">
        <p>
          ITエンジニアを目指したい方、現役エンジニアで新しい技術を習得したい方向けの学習コンテンツを制作しています。
          <br>
          技術トレンドに合わせて開発現場で役立つスキルを学べる内容を取り入れています。
        </p>
      </div>
    </div>

    <div class="flex flex-col items-center">
      <div class="mb-5">
        <h3 class="text-xl border-b-4 px-4 font-semibold inline">Udemy</h3>
      </div>
      <p class="mb-5 ml-3">
        UdemyにてITエンジニア向けの学習コンテンツを提供しており、これまでで延べ35,000人以上の受講生の方に受講いただいております。
        <br>
        割引価格で購入いただけるクーポンを発行しておりますので、興味のある方はお問い合わせフォームからご希望のコースをご連絡ください。
      </p>
    </div>

    <a href="https://www.udemy.com/course/docker-k/?referralCode=E7ED825BC859AD0E5D9D" target="_blank"
       class="flex items-center sm:flex-row flex-col mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500">
      <div>
        <img class="object-cover w-48" src="@/assets/docker.jpg" alt="Docker">
      </div>
      <p class="sm:ml-4 my-auto sm:pt-0 pt-2 text-white sm:text-lg text-sm">ゼロからはじめる Dockerによるアプリケーション実行環境構築</p>
    </a>
    <a href="https://www.udemy.com/course/rails-kj/?referralCode=052722308EDAEEE70FA2" target="_blank"
       class="flex items-center sm:flex-row flex-col mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl bg-gradient-to-r from-red-500 to-red-700">
      <div>
        <img class="object-cover w-48" src="@/assets/rails.jpg" alt="Ruby on Rails">
      </div>
      <p class="sm:ml-4 my-auto sm:pt-0 pt-2 text-white sm:text-lg text-sm">フルスタックエンジニアが教える
        即戦力Railsエンジニア養成講座</p>
    </a>
    <a href="https://www.udemy.com/course/vuejs-firebase/?referralCode=54339DCB483ED8CACB40" target="_blank"
       class="flex items-center sm:flex-row flex-col mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl bg-gradient-to-r from-emerald-400 to-emerald-500">
      <div>
        <img class="object-cover w-48" src="@/assets/vue-firebase.jpg" alt="Vue.js & Firebase">
      </div>
      <p class="sm:ml-4 my-auto sm:pt-0 pt-2 text-white sm:text-lg text-sm">Vue.js + Firebaseで作るシングルページアプリケーション</p>
    </a>
    <a href="https://www.udemy.com/course/amazon-web-service-ec2/?referralCode=F5B295163B3A87844331" target="_blank"
       class="flex items-center sm:flex-row flex-col mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl bg-gradient-to-r from-orange-400 to-orange-500">
      <div>
        <img class="object-cover w-48" src="@/assets/EC2.jpg" alt="AWS EC2">
      </div>
      <p class="sm:ml-4 my-auto sm:pt-0 pt-2 text-white sm:text-lg text-sm">Amazon Web Service マスターコース EC2編</p>
    </a>
    <a href="https://www.udemy.com/course/amazon-web-service-vpc/?referralCode=C5F65F3DFA0B072574ED" target="_blank"
       class="flex items-center sm:flex-row flex-col mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl bg-gradient-to-r from-cyan-500 to-blue-500">
      <div>
        <img class="object-cover w-48" src="@/assets/VPC.jpg" alt="AWS VPC">
      </div>
      <p class="sm:ml-4 my-auto sm:pt-0 pt-2 text-white sm:text-lg text-sm">Amazon Web Service マスターコース VPC編</p>
    </a>

    <div class="my-20">
      <div class="flex flex-col items-center">
        <h3 class="text-xl border-b-4 px-4 font-semibold inline">著書</h3>
      </div>
      <a href="https://www.shuwasystem.co.jp/book/9784798054520.html" target="_blank"
         class="mt-8 flex justify-center mb-5 sm:w-2/3 bg-slate-100 p-5 mx-auto rounded-xl">
        <div class="flex items-center justify-center sm:flex-row flex-col my-5">
          <div>
            <img class="object-cover w-32" src="@/assets/docker-book.jpg"
                 alt="ITエンジニアになる! チャレンジDocker仮想化環境構築入門">
          </div>
          <p class="sm:ml-4 sm:pt-0 pt-5 sm:text-lg text-sm">
            ITエンジニアになる! チャレンジDocker仮想化環境構築入門
            <br>
            2018/8/21
          </p>
        </div>
      </a>
    </div>
  </div>
</template>
<script setup>
</script>